import { createTheme, Theme } from "@mui/material/styles";
import { CSSProperties } from "@mui/material/styles/createTypography";
import {
  duotoneDark,
  duotoneEarth,
  duotoneForest,
  duotoneLight,
  duotoneSea,
  duotoneSpace,
} from "react-syntax-highlighter/dist/cjs/styles/prism";

const purpleLight = "#915073";
const purpleMain = "#713e5a";
const purpleDark = "#5C3349";
const purpleDarkest = "#351D2A";

const redLight = "#CD5B51";
const redMain = "#C74B3F";
const redDark = "#9D382F";
const redDarkest = "#3F1612";

const whiteLight = "#FFFFFF";
const whiteMain = "#F9F5E3";
const whiteDark = "#F4EDCD";
const whiteDarkest = "#EDE1AB";

const blueLight = "#204A5A";
const blueMain = "#122932";
const blueDark = "#0B191E";
const blueDarkest = "#060C0F";

const greenLight = "#4C5639";
const greenMain = "#363d29";
const greenDark = "#2B3120";
const greenDarkest = "#151910";

const tanLight = "#9B8966";
const tanMain = "#887859";
const tanDark = "#6F6249";
const tanDarkest = "#3E3628";

const orangeLight = "#EFA980";
const orangeMain = "#EA8C55";
const orangeDark = "#E67737";

declare module "@mui/material/styles" {
  interface PaletteColor {
    darkest?: string;
  }
}

const coreTheme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        color: "secondary",
        variant: "contained",
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          overflow: "hidden",
          "& ::selection": {
            backgroundColor: orangeMain,
            color: whiteMain,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
  },
  typography: {
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 700,
    },
    h1: {
      fontSize: "3rem",
      '@media (max-width:414px)': {
        fontSize: '2.25rem',
      },
    },
    h2: {
      fontSize: "2.5rem",
      '@media (max-width:414px)': {
        fontSize: '1.5rem',
      },
    },
    h3: {
      fontSize: "2rem",
      '@media (max-width:414px)': {
        fontSize: '1.25rem',
      },
    },
    h4: {
      fontSize: "1.25rem",
      fontWeight: 700,
      '@media (max-width:414px)': {
        fontSize: '1.1rem',
      },
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 700,
      '@media (max-width:414px)': {
        fontSize: '1.1rem',
      },
    },
    subtitle1: {
      fontSize: ".85rem",
      '@media (max-width:414px)': {
        fontSize: '.75rem',
      },
    },
    subtitle2: {
      fontSize: ".75rem",
      fontWeight: 300,
      '@media (max-width:414px)': {
        fontSize: '.65rem',
      },
    },
  },
});

const purpleTheme = createTheme(coreTheme, {
  typography: {
    h4: {
      color: whiteMain,
    },
  },
  palette: {
    background: {
      default: purpleMain,
    },
    primary: {
      light: purpleLight,
      main: purpleMain,
      dark: purpleDark,
      darkest: purpleDarkest,
      contrastText: whiteMain,
    },
    secondary: {
      light: whiteLight,
      main: whiteMain,
      dark: whiteDark,
      contrastText: blueMain,
    },
    info: {
      light: orangeLight,
      main: orangeMain,
      dark: orangeDark,
      contrastText: whiteMain,
    },
  },
});

const redTheme = createTheme(coreTheme, {
  typography: {
    h4: {
      color: purpleMain,
    },
  },
  palette: {
    background: {
      default: redMain,
    },
    primary: {
      light: redLight,
      main: redMain,
      dark: redDark,
      darkest: redDarkest,
      contrastText: whiteMain,
    },
    secondary: {
      light: purpleLight,
      main: purpleMain,
      dark: purpleDark,
      contrastText: whiteMain,
    },
    info: {
      light: orangeLight,
      main: orangeMain,
      dark: orangeDark,
      contrastText: whiteMain,
    },
  },
});

const whiteTheme = createTheme(coreTheme, {
  typography: {
    h4: {
      color: redMain,
    },
  },
  palette: {
    background: {
      default: whiteMain,
    },
    primary: {
      light: whiteLight,
      main: whiteMain,
      dark: whiteDark,
      darkest: whiteDarkest,
      contrastText: blueMain,
    },
    secondary: {
      light: redLight,
      main: redMain,
      dark: redDark,
      contrastText: whiteMain,
    },
    info: {
      light: orangeLight,
      main: orangeMain,
      dark: orangeDark,
      contrastText: whiteMain,
    },
  },
});

export { whiteTheme };

const blueTheme = createTheme(coreTheme, {
  typography: {
    h4: {
      color: tanMain,
    },
  },
  palette: {
    background: {
      default: blueMain,
    },
    primary: {
      light: blueLight,
      main: blueMain,
      dark: blueDark,
      darkest: blueDarkest,
      contrastText: whiteMain,
    },
    secondary: {
      light: tanLight,
      main: tanMain,
      dark: tanDark,
      contrastText: whiteMain,
    },
    info: {
      light: orangeLight,
      main: orangeMain,
      dark: orangeDark,
      contrastText: whiteMain,
    },
  },
});

const greenTheme = createTheme(coreTheme, {
  typography: {
    h4: {
      color: blueMain,
    },
  },
  palette: {
    background: {
      default: greenMain,
    },
    primary: {
      light: greenLight,
      main: greenMain,
      dark: greenDark,
      darkest: greenDarkest,
      contrastText: whiteMain,
    },
    secondary: {
      light: blueLight,
      main: blueMain,
      dark: blueDark,
      contrastText: whiteMain,
    },
    info: {
      light: orangeLight,
      main: orangeMain,
      dark: orangeDark,
      contrastText: whiteMain,
    },
  },
});

const tanTheme = createTheme(coreTheme, {
  typography: {
    h4: {
      color: greenMain,
    },
  },
  palette: {
    background: {
      default: tanMain,
    },
    primary: {
      light: tanLight,
      main: tanMain,
      dark: tanDark,
      darkest: tanDarkest,
      contrastText: whiteMain,
    },
    secondary: {
      light: greenLight,
      main: greenMain,
      dark: greenDark,
      contrastText: whiteMain,
    },
    info: {
      light: orangeLight,
      main: orangeMain,
      dark: orangeDark,
      contrastText: whiteMain,
    },
  },
});

const themes: Map<Theme, any> = new Map<Theme, any>();

themes.set(purpleTheme as Theme, duotoneDark as CSSProperties);
themes.set(redTheme as Theme, duotoneSpace as CSSProperties);
themes.set(whiteTheme as Theme, duotoneLight as CSSProperties);
themes.set(blueTheme as Theme, duotoneSea as CSSProperties);
themes.set(greenTheme as Theme, duotoneForest as CSSProperties);
themes.set(tanTheme as Theme, duotoneEarth as CSSProperties);

const themesCount = themes.size;
const themesKeys = Array.from(themes.keys());

export const getRandomTheme = (prevTheme?: Theme): Theme => {
  const newTheme = themesKeys[Math.floor(themesCount * Math.random())];

  if (newTheme === prevTheme) return getRandomTheme(prevTheme);

  return newTheme;
};

export const getPrismTheme = (theme: Theme) => themes.get(theme);
