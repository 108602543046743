import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import {
  BlockQuoteElement,
  CodeBlockElement,
  TypographyElement,
} from "../../models";
import { CenterMiddlePageLayout } from "../../layout";
import NotFoundPage from "../not-found/NotFoundPage";
import { ArrowCircleIconsRow } from "../../components";
import { works } from "../../content";
const parse = require("html-react-parser");

const WorkPage: React.FC = () => {
  const params = useParams();
  const workId = params?.workId ?? "";

  const work = works.filter((curr, index) => curr.slug === workId)[0];

  if (work === undefined) return <NotFoundPage />;

  const workIndex = works.indexOf(work);

  const generateTypographyElement = (
    typography: TypographyElement,
    index: number
  ) => {
    const isHeader = ["h1", "h2", "h3", "h4", "h5", "h6"].includes(
      typography.variant
    );

    return (
      <Typography
        key={index}
        sx={{
          marginBottom: isHeader ? ".5rem" : "1rem",
          "& a": {
            color: "info.main",
            textDecoration: "none",
          },
          "& a:hover": {
            color: "info.main",
            textDecoration: "underline",
          },
        }}
        variant={typography.variant}
      >
        {parse(typography.raw)}
      </Typography>
    );
  };

  const generateWorkContent = () =>
    work!.content.map(
      (
        element: TypographyElement | CodeBlockElement | BlockQuoteElement,
        index: number
      ) => generateTypographyElement(element as TypographyElement, index)
    );

  return (
    <CenterMiddlePageLayout maxWidth="50rem">
      <Paper>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          sx={{
            height: "30rem",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              backgroundColor: "primary.dark",
              color: "primary.light",
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                lineHeight: "3rem",
                margin: ".5rem 2rem .5rem .5rem",
              }}
              variant="h1"
            >
              {work.title}
            </Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: "primary.light",
              color: "primary.contrastText",
              display: "flex",
              flexDirection: "column",
              height: "30rem",
              overflow: "auto",
              padding: "1rem",
              "&::-webkit-scrollbar": {
                backgroundColor: "inherit",
                height: ".75rem",
                width: ".75rem",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "primary.dark",
              },
              "&::-webkit-scrollbar-corner": {
                backgroundColor: "inherit",
              },
            }}
          >
            {generateWorkContent()}
            <Box
              sx={{
                display: "flex",
                marginLeft: "auto",
                marginTop: "auto",
              }}
            >
              <Typography marginTop="1rem" variant="subtitle1">
                Completed in {work.completedString}
              </Typography>
            </Box>
          </Box>
        </Stack>
      </Paper>
      <ArrowCircleIconsRow
        nextPath={`/work/${
          works[(workIndex + 1 + works.length) % works.length].slug
        }`}
        previousPath={`/work/${
          works[(workIndex - 1 + works.length) % works.length].slug
        }`}
      />
    </CenterMiddlePageLayout>
  );
};

export default WorkPage;
