import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";

const Footer: React.FC = () => {
  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "info.main",
        color: "info.contrastText",
        display: "flex",
        minHeight: "2rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          marginRight: "2rem",
          marginLeft: "auto",
        }}
      >
        <Typography variant="body2">{`© ${new Date().getFullYear()}`}</Typography>
      </Box>
    </Box>
  );
};

export default Footer;
