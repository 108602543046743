import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ArrowCircleRightIconRow } from "../../components";

interface WorkPreviewProps {
  description: string;
  slug: string;
  title: string;
}

const WorkPreview: React.FC<WorkPreviewProps> = (props) => {
  const { description, slug, title } = props;

  return (
    <Paper>
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "primary.dark",
          color: "primary.light",
          display: "flex",
          minHeight: "5rem",
          padding: ".5rem",
          // "-webkit-text-stroke": ".01rem primary.contrastText",
          "-webkit-text-stroke-width": ".01rem",
          "-webkit-text-stroke-color": "primary.contrastText",
        }}
      >
        <Typography variant="h5">{title}</Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "primary.light",
          color: "primary.contrastText",
          display: "flex",
          flexDirection: "column",
          minHeight: "7.5rem",
          padding: ".5rem",
        }}
      >
        <Typography variant="body1">{description}</Typography>
        <ArrowCircleRightIconRow path={`/work/${slug}`} />
      </Box>
    </Paper>
  );
};

export default WorkPreview;
