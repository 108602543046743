import Typography from "@mui/material/Typography";

interface BlockQuoteProps {
  children: any;
}

const BlockQuote: React.FC<BlockQuoteProps> = (props) => {
  const { children } = props;

  return (
    <Typography
      sx={{
        backgroundColor: "primary.light",
        borderRadius: ".25rem",
        marginBottom: "2rem",
        padding: "1rem",
      }}
    >
      {children}
    </Typography>
  );
};

export default BlockQuote;
