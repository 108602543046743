import { Theme } from "@mui/material";
import { createContext, useContext } from "react";
import { getPrismTheme, getRandomTheme, whiteTheme } from "./theme";

export const CustomThemeContext = createContext<{
  codeTheme: any;
  nextTheme: Theme;
  theme: Theme;
  setTheme?: (newTheme: Theme) => void;
}>({
  codeTheme: getPrismTheme(whiteTheme),
  nextTheme: getRandomTheme(whiteTheme),
  theme: whiteTheme,
});

export const useCustomThemeContext = () => useContext(CustomThemeContext);
