import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import { ErrorPage } from "../pages";
import Footer from "./Footer";
import Header from "./Header";

const PageLayout: React.FC = () => {
  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        color: "primary.contrastText",
        height: "100vh",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          backgroundColor: "inherit",
          height: ".75rem",
          width: ".75rem",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "primary.dark",
        },
        "&::-webkit-scrollbar-corner": {
          backgroundColor: "inherit",
        },
      }}
    >
      <ErrorPage>
        <Header />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "0 2rem 2rem 2rem",
            minHeight: "calc(100vh - 8rem)",
          }}
        >
          <Outlet />
        </Box>
      </ErrorPage>
      <Footer />
    </Box>
  );
};

export default PageLayout;
