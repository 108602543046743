import Typography from "@mui/material/Typography";
import { CenterMiddlePageLayout } from "../../layout";

const LandingPage: React.FC = () => {
  return (
    <CenterMiddlePageLayout maxWidth="50rem">
      <Typography variant="h1">Hey, I'm Katie Prochilo.</Typography>
      <Typography variant="h2">
        I'm a software engineer working at Fanatics Betting & Gaming out of Chicago.
      </Typography>
    </CenterMiddlePageLayout>
  );
};

export default LandingPage;
