import { Work } from "../../../models";
import * as adoptionBot from "./AdoptionBot.json";
import * as callCenterHelpBot from "./CallCenterHelpBot.json";
import * as cashierSpeechToOrderSolution from "./CashierSpeechToOrderSolution.json";
import * as cicdForCustomSpeech from "./CICDForCustomSpeech.json";
import * as customerSpeechToOrderSolution from "./CustomerSpeechToOrderSolution.json";
import * as katieProchilo from "./KatieProchilo.json";
import * as liveStreamingPoC from "./LiveStreamingPoC.json";
import * as microsoftTeamsForJustice from "./MicrosoftTeamsForJustice.json";
import * as openHackCoach from "./OpenHackCoach.json";
import * as videoAnalyzerForSearch from "./VideoAnalyzerForSearch.json";

const works: Work[] = [
  customerSpeechToOrderSolution as Work,
  cashierSpeechToOrderSolution as Work,
  microsoftTeamsForJustice as Work,
  liveStreamingPoC as Work,
  cicdForCustomSpeech as Work,
  videoAnalyzerForSearch as Work,
  katieProchilo as Work,
  callCenterHelpBot as Work,
  adoptionBot as Work,
  openHackCoach as Work,
];

works.sort((a, b) => a.completed > b.completed ? -1 : a.completed < b.completed ? 1 : 0);

export default works;
