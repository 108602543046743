import Link from "@mui/material/Link";

interface ExternalNavigationLinkProps {
  href: string;
  children: any;
}

const ExternalNavigationIconLink: React.FC<ExternalNavigationLinkProps> = (
  props
) => {
  const { href, children } = props;

  return (
    <Link
      href={href}
      rel="noopener noreferrer"
      sx={{
        marginRight: "1rem",
      }}
      target="_blank"
    >
      {children}
    </Link>
  );
};

export default ExternalNavigationIconLink;
