import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";

interface ArrowCircleIconsRowProps {
  nextPath: string;
  previousPath: string;
}

const ArrowCircleIconsRow: React.FC<ArrowCircleIconsRowProps> = (props) => {
  const { nextPath, previousPath } = props;
  const navigate = useNavigate();

  const handleNavigate = (path: string) => navigate(path);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        marginTop: "auto",
        minHeight: "5rem",
        width: "100%",
      }}
    >
      <IconButton
        aria-label="previous work"
        onClick={() => handleNavigate(previousPath)}
        sx={{
          padding: 0,
          width: "4.5rem",
          "&:hover": {
            backgroundColor: "inherit",
          },
        }}
      >
        <ArrowCircleLeftIcon
          sx={{
            color: "secondary.main",
            height: "2rem",
            width: "2rem",
            "&:hover": {
              color: "secondary.dark",
              height: "2.5rem",
              width: "2.5rem",
            },
          }}
        />
      </IconButton>
      <IconButton
        aria-label="next work"
        onClick={() => handleNavigate(nextPath)}
        sx={{
          padding: 0,
          width: "4.5rem",
          "&:hover": {
            backgroundColor: "inherit",
          },
        }}
      >
        <ArrowCircleRightIcon
          sx={{
            color: "secondary.main",
            height: "2rem",
            width: "2rem",
            "&:hover": {
              color: "secondary.dark",
              height: "2.5rem",
              width: "2.5rem",
            },
          }}
        />
      </IconButton>
    </Box>
  );
};

export default ArrowCircleIconsRow;
