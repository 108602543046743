import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { ArrowCircleRightIconRow, Tag } from "../../components";

interface GuidePreviewProps {
  description: string;
  slug: string;
  tags: string[];
  title: string;
}

const GuidePreview: React.FC<GuidePreviewProps> = (props) => {
  const { description, slug, tags, title } = props;

  return (
    <Paper>
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "primary.dark",
          display: "flex",
          flexDirection: "column",
          justifyItems: "center",
          minHeight: "7rem",
          padding: ".5rem",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            alignItems: "center",
            color: "primary.light",
            display: "flex",
            margin: "auto",
            width: "100%",
          }}
          variant="h5"
        >
          {title}
        </Typography>
        <Box
          sx={{
            color: "primary.contrastText",
            display: "flex",
            flexDirection: "row",
            margin: "auto 0 0 -1rem",
            width: "100%",
          }}
        >
          {tags.map((tag: string, index: number) => (
            <Tag key={index} tag={tag} />
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "primary.light",
          color: "primary.contrastText",
          display: "flex",
          flexDirection: "column",
          minHeight: "7.5rem",
          padding: ".5rem",
        }}
      >
        <Typography variant="body1">{description}</Typography>
        <ArrowCircleRightIconRow path={`/guides/${slug}`} />
      </Box>
    </Paper>
  );
};

export default GuidePreview;
