import Grid from "@mui/material/Grid";
import { Work } from "../../models";
import { CenterMiddlePageLayout } from "../../layout";
import { works } from "../../content";
import WorkPreview from "../works/WorkPreview";

const workPreviews = works.map((work: Work, index: number) => (
  <Grid key={index} item xs={12} sm={4} md={4} lg={3}>
    <WorkPreview
      description={work.description}
      key={index}
      slug={work.slug}
      title={work.title}
    />
  </Grid>
));

const WorksPage: React.FC = () => (
  <CenterMiddlePageLayout maxWidth="60rem">
    <Grid alignItems="center" container justifyContent="center" spacing={1}>
      {workPreviews}
    </Grid>
  </CenterMiddlePageLayout>
);

export default WorksPage;
