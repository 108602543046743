import Box from "@mui/material/Box";

interface CenterTopPageLayoutProps {
  children: any;
}

const CenterTopPageLayout: React.FC<CenterTopPageLayoutProps> = (props) => {
  const { children } = props;

  return (
    <Box
      sx={{
        marginBottom: "4rem",
        maxWidth: "50rem",
        width: "100%",
      }}
    >
      {children}
    </Box>
  );
};

export default CenterTopPageLayout;
