import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React from "react";
import { CenterMiddlePageLayout } from "../../layout";
import { InternalNavigationLink } from "../../components";

interface ErrorBoundaryProps {
  children: any;
}

interface ErrorPageState {
  hasError: boolean;
}

class ErrorPage extends React.Component<ErrorBoundaryProps, ErrorPageState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: any) {
    return { hasError: true };
  }

  async handleRefresh() {
    await window.location.reload();
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Box>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              height: "4rem",
              marginLeft: "2rem",
            }}
          >
            <InternalNavigationLink handleClick={this.handleRefresh}>
              KP
            </InternalNavigationLink>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "0 2rem 2rem 2rem",
              minHeight: "calc(100vh - 8rem)",
            }}
          >
            <CenterMiddlePageLayout maxWidth="50rem">
              <Typography variant="h1">Something went wrong</Typography>
              <Typography marginBottom="2rem" variant="h2">
                We couldn't load the page. Try refreshing to fix the problem.
              </Typography>
              <Button onClick={this.handleRefresh}>Refresh</Button>
            </CenterMiddlePageLayout>
          </Box>
        </Box>
      );
    }

    return children;
  }
}

export default ErrorPage;
