import Box from "@mui/material/Box";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import bash from "react-syntax-highlighter/dist/cjs/languages/prism/bash";
import javascript from "react-syntax-highlighter/dist/cjs/languages/prism/javascript";
import jsx from "react-syntax-highlighter/dist/cjs/languages/prism/jsx";
import tsx from "react-syntax-highlighter/dist/cjs/languages/prism/tsx";
import typescript from "react-syntax-highlighter/dist/cjs/languages/prism/typescript";
import { CodeLanguage } from "../models";
import { useCustomThemeContext } from "../theme";

SyntaxHighlighter.registerLanguage("bash", bash);
SyntaxHighlighter.registerLanguage("javascript", javascript);
SyntaxHighlighter.registerLanguage("jsx", jsx);
SyntaxHighlighter.registerLanguage("tsx", tsx);
SyntaxHighlighter.registerLanguage("typescript", typescript);

interface CodeBlockProps {
  language: CodeLanguage;
  children: any;
}

const CodeBlock: React.FC<CodeBlockProps> = (props) => {
  const { language, children } = props;
  const { codeTheme, theme } = useCustomThemeContext();

  return (
    <Box
      sx={{
        overflowX: "auto",
        "pre::-webkit-scrollbar": {
          backgroundColor: "inherit",
          height: ".75rem",
          width: ".75rem",
        },
        "pre::-webkit-scrollbar-thumb": {
          backgroundColor: "primary.dark",
        },
        "pre::-webkit-scrollbar-corner": {
          backgroundColor: "inherit",
        },
      }}
    >
      <SyntaxHighlighter
        codeTagProps={{
          style: {
            backgroundColor: theme.palette.primary.darkest,
          },
        }}
        customStyle={{
          backgroundColor: theme.palette.primary.darkest,
          borderRadius: ".25rem",
          marginBottom: "2rem",
          padding: "1rem",
        }}
        language={language}
        style={codeTheme}
      >
        {children}
      </SyntaxHighlighter>
    </Box>
  );
};

export default CodeBlock;
