import { Guide } from "../../../models";
// import * as configureGitGlobally from "./ConfigureGitGlobally.json";
import * as gitForksAndUpstreams from "./GitForksAndUpstreams.json";
import * as pullWithRebase from "./PullWithRebase.json";
import * as rebaseOntoTheUpstream from "./RebaseOntoTheUpstream.json";
import * as renameTheMasterBranchToMain from "./RenameTheMasterBranchToMain.json";

const guides: Guide[] = [
  // configureGitGlobally as Guide,
  gitForksAndUpstreams as Guide,
  pullWithRebase as Guide,
  rebaseOntoTheUpstream as Guide,
  renameTheMasterBranchToMain as Guide,
];

guides.sort((a, b) => a.lastUpdated > b.lastUpdated ? -1 : a.lastUpdated < b.lastUpdated ? 1 : 0);

export default guides;
