import GitHubIcon from "@mui/icons-material/GitHub";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
import CircleIcon from '@mui/icons-material/Circle';
import Box from "@mui/material/Box";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useCustomThemeContext } from "../theme";
import { ExternalNavigationIconLink, InternalNavigationLink } from "../components";

const Header: React.FC = () => {
  const { nextTheme, setTheme } = useCustomThemeContext();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        height: "4rem",
        marginLeft: "2rem",
      }}
    >
      <InternalNavigationLink handleClick={() => navigate("/")}>
        KP
      </InternalNavigationLink>
      <Box
        sx={{
          display: "flex",
          marginRight: "1rem",
          marginLeft: "auto",
        }}
      >
        {/* <InternalNavigationLink handleNavigate={() => handleNavigate("/about")}>
          About
        </InternalNavigationLink> */}
        <InternalNavigationLink handleClick={() => navigate("/work")}>
          Work
        </InternalNavigationLink>
        <InternalNavigationLink handleClick={() => navigate("/guides")}>
          Guides
        </InternalNavigationLink>
        <InternalNavigationLink handleClick={() => navigate("/contact")}>
          Contact
        </InternalNavigationLink>
        <ExternalNavigationIconLink href="https://github.com/KatieProchilo">
          <GitHubIcon
            sx={{
              color: "info.main",
              verticalAlign: "middle",
              "&:hover": {
                color: "info.dark",
              },
            }}
          />
        </ExternalNavigationIconLink>
        {/* <ExternalNavigationIconLink href="https://linkedin.com/in/katieprochilo/">
          <LinkedInIcon
            sx={{
              color: "info.main",
              verticalAlign: "middle",
              "&:hover": {
                color: "info.dark",
              },
            }}
          />
        </ExternalNavigationIconLink> */}
        <InternalNavigationLink
          hoverColor={nextTheme?.palette.primary.main}
          handleClick={() => setTheme!(nextTheme)}
        >
          <CircleIcon
            sx={{
              color: "info.main",
              verticalAlign: "middle",
              "&:hover": {
                color: `${nextTheme?.palette.primary.main}`,
              },
            }}
          />
        </InternalNavigationLink>
      </Box>
    </Box>
  );
};

export default Header;
