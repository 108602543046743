import Typography from "@mui/material/Typography";
import { CenterMiddlePageLayout } from "../../layout";

const ContactPage: React.FC = () => {
  return (
    <CenterMiddlePageLayout maxWidth="50rem">
      <Typography variant="h1">Let's get in touch!</Typography>
      <Typography variant="h2">Email me at katieprochilo2@gmail.com</Typography>
    </CenterMiddlePageLayout>
  );
};

export default ContactPage;
