import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";

interface ArrowCircleRightIconRowProps {
  path: string;
}

const ArrowCircleRightIconRow: React.FC<ArrowCircleRightIconRowProps> = (
  props
) => {
  const { path } = props;
  const navigate = useNavigate();

  const handleNavigate = (path: string) => navigate(path);

  return (
    <Box
      sx={{
        display: "flex",
        marginLeft: "auto",
        marginTop: "auto",
      }}
    >
      <IconButton
        aria-label="read more"
        onClick={() => handleNavigate(path)}
        sx={{
          margin: ".25rem",
          padding: 0,
          "&:hover": {
            backgroundColor: "inherit",
            margin: 0,
          },
        }}
      >
        <ArrowCircleRightIcon
          sx={{
            height: "1.625rem",
            width: "1.625rem",
            color: "secondary.main",
            "&:hover": {
              color: "secondary.dark",
              height: "2.125rem",
              width: "2.125rem",
            },
          }}
        />
      </IconButton>
    </Box>
  );
};

export default ArrowCircleRightIconRow;
