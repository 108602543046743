import * as React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PageLayout } from "./layout";
import {
  ContactPage,
  GuidePage,
  GuidesPage,
  LandingPage,
  NotFoundPage,
  WorkPage,
  WorksPage
} from "./pages";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PageLayout />}>
          <Route path="*" element={<NotFoundPage />} />
          <Route index element={<LandingPage />} />
          {/* <Route path="about" element={<AboutPage />} /> */}
          <Route path="work/">
            <Route index element={<WorksPage />} />
            <Route path=":workId" element={<WorkPage />} />
          </Route>
          <Route path="guides/">
            <Route index element={<GuidesPage />} />
            <Route path=":guideId" element={<GuidePage />} />
          </Route>
          <Route path="contact" element={<ContactPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
