import Grid from "@mui/material/Grid";
import { Guide } from "../../models";
import { CenterMiddlePageLayout } from "../../layout";
import GuidePreview from "./GuidePreview";
import { guides } from "../../content";

const guidePreviews = guides.map((guide: Guide, index: number) => (
  <Grid key={index} item xs={12} sm={4} md={4} lg={3}>
    <GuidePreview
      description={guide.description}
      key={index}
      slug={guide.slug}
      tags={guide.tags}
      title={guide.title}
    />
  </Grid>
));

const GuidesPage: React.FC = () => (
  <CenterMiddlePageLayout maxWidth="60rem">
    <Grid alignItems="center" container justifyContent="center" spacing={1}>
      {guidePreviews}
    </Grid>
  </CenterMiddlePageLayout>
);

export default GuidesPage;
