import Typography from "@mui/material/Typography";

interface TagProps {
  tag: string;
}

const Tag: React.FC<TagProps> = (props) => {
  const { tag } = props;

  return (
    <Typography
      sx={{
        backgroundColor: "primary.light",
        borderRadius: ".75rem",
        padding: ".25rem .75rem",
        marginBottom: ".5rem",
        marginLeft: ".5rem",
      }}
      variant="subtitle2"
    >
      {tag}
    </Typography>
  );
};

export default Tag;
