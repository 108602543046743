import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";
import {
  BlockQuoteElement,
  CodeBlockElement,
  TypographyElement,
} from "../../models";
import { CenterTopPageLayout } from "../../layout";
import NotFoundPage from "../not-found/NotFoundPage";
import { ArrowCircleIconsRow, BlockQuote, CodeBlock } from "../../components";
import { guides } from "../../content";
const parse = require("html-react-parser");

const GuidePage: React.FC = () => {
  const params = useParams();
  const guideId = params?.guideId ?? "";

  const guide = guides.filter((curr) => curr.slug === guideId)[0];

  if (guide === undefined) return <NotFoundPage />;

  const guideIndex = guides.indexOf(guide);

  const generateBlockQuoteElement = (
    blockQuote: BlockQuoteElement,
    index: number
  ) => <BlockQuote key={index}>{parse(blockQuote.raw)}</BlockQuote>;

  const generateCodeBlockElement = (code: CodeBlockElement, index: number) => {
    return (
      <CodeBlock key={index} language={code.language}>
        {code.raw}
      </CodeBlock>
    );
  };

  const generateTypographyElement = (
    typography: TypographyElement,
    index: number
  ) => {
    const isHeader = ["h1", "h2", "h3", "h4", "h5", "h6"].includes(
      typography.variant
    );

    return (
      <Typography
        key={index}
        marginBottom={isHeader ? ".5rem" : "1rem"}
        variant={typography.variant}
      >
        {parse(typography.raw)}
      </Typography>
    );
  };

  const generateGuideContent = () => {
    return guide!.content.map((element: any, index: number) => {
      if (element.type === "typography")
        return generateTypographyElement(element as TypographyElement, index);
      else if (element.type === "code")
        return generateCodeBlockElement(element as CodeBlockElement, index);
      else if (element.type === "blockQuote")
        return generateBlockQuoteElement(element as BlockQuoteElement, index);
      else return undefined;
    });
  };

  return (
    <CenterTopPageLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Typography align="center" component="h1" variant="h3">
          {guide.title}
        </Typography>
        <Typography align="center" marginBottom="2rem" variant="subtitle1">
          Last updated {guide.lastUpdatedString}
        </Typography>
        {generateGuideContent()}
        <ArrowCircleIconsRow
          nextPath={`/guides/${
            guides[(guideIndex + 1 + guides.length) % guides.length].slug
          }`}
          previousPath={`/guides/${
            guides[(guideIndex - 1 + guides.length) % guides.length].slug
          }`}
        />
      </Box>
    </CenterTopPageLayout>
  );
};

export default GuidePage;
