import Box from "@mui/material/Box";
import CenterMiddleFullWidthPageLayout from "./CenterMiddleFullWidthPageLayout";

interface CenterMiddlePageLayoutProps {
  maxWidth: string;
  children: any;
}

const CenterMiddlePageLayout: React.FC<CenterMiddlePageLayoutProps> = (
  props
) => {
  const { maxWidth, children } = props;

  return (
    <CenterMiddleFullWidthPageLayout>
      <Box
        sx={{
          maxWidth: maxWidth,
          width: "100%",
        }}
      >
        {children}
      </Box>
    </CenterMiddleFullWidthPageLayout>
  );
};

export default CenterMiddlePageLayout;
