import Link from "@mui/material/Link";

interface InternalNavigationLinkProps {
  hoverColor?: string;
  handleClick: () => void;
  children: any;
}

const InternalNavigationLink: React.FC<InternalNavigationLinkProps> = (
  props
) => {
  const { hoverColor, handleClick, children } = props;

  return (
    <Link
      color="info.main"
      sx={{
        fontWeight: 900,
        marginRight: "1rem",
        textDecoration: "none",
        "&:hover": {
          color: `${hoverColor ?? "info.dark"}`,
          cursor: "pointer",
        },
      }}
      onClick={() => handleClick()}
    >
      {children}
    </Link>
  );
};

export default InternalNavigationLink;
