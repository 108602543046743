import Box from "@mui/material/Box";

interface CenterMiddleFullWidthPageLayoutProps {
  children: any;
}

const CenterMiddleFullWidthPageLayout: React.FC<
  CenterMiddleFullWidthPageLayoutProps
> = (props) => {
  const { children } = props;

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      {children}
    </Box>
  );
};

export default CenterMiddleFullWidthPageLayout;
