import { Theme, ThemeProvider } from "@mui/material";
import { FunctionComponent, useState } from "react";
import { CustomThemeContext } from "./CustomThemeContext";
import { getPrismTheme, getRandomTheme, whiteTheme } from "./theme";

interface CustomThemeProviderProps {
  children: any;
}

const CustomThemeProvider: FunctionComponent<CustomThemeProviderProps> = (
  props
) => {
  const { children } = props;

  const [theme, _setTheme] = useState<Theme>(whiteTheme);
  const [nextTheme, setNextTheme] = useState<Theme>(getRandomTheme(whiteTheme));

  const setTheme = (newTheme: Theme) => {
    _setTheme(newTheme);
    setNextTheme(getRandomTheme(newTheme));
  };

  return (
    <CustomThemeContext.Provider
      value={{
        codeTheme: getPrismTheme(theme),
        nextTheme: nextTheme,
        theme: theme,
        setTheme: setTheme,
      }}
    >
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </CustomThemeContext.Provider>
  );
};

export default CustomThemeProvider;
