import Typography from "@mui/material/Typography";
import { CenterMiddlePageLayout } from "../../layout";

const NotFoundPage: React.FC = () => {
  return (
    <CenterMiddlePageLayout maxWidth="50rem">
      <Typography variant="h1">Page not found</Typography>
      <Typography variant="h2">Aw nuts! Looks like you got lost.</Typography>
    </CenterMiddlePageLayout>
  );
};

export default NotFoundPage;
